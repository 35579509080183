import React from 'react';
import { graphql, Link } from 'gatsby';

import HomeLayout from 'layouts/HomeLayout';
import {
  Albums,
  AlbumsPreview,
  Features,
  Guides,
  Selectors,
  SEO,
} from 'components';
import Button from 'components/common/Button';
import {
  HeaderWrap,
  InnerWrapCenter,
  MobilePageHeader,
  MobileLatestInterview,
  MobileLatestAlbums,
} from 'components/common/Containers/styled';

const Home = ({ data }) => {
  const {
    latestInterview,
    latestAlbums,
    latestSelectors,
    latestFeatures,
    latestGuides,
  } = data;

  return (
    <HomeLayout {...latestInterview}>
      <SEO description='Japanese music in the spotlight.' />

      <MobilePageHeader>
        <h1>The Glow</h1>
      </MobilePageHeader>

      <MobileLatestInterview>
        <Features {...latestInterview} preview={true} />
      </MobileLatestInterview>

      <AlbumsPreview {...latestAlbums} />

      <MobileLatestAlbums>
        <HeaderWrap>
          <h3>New reccs</h3>
          <Link to='/new-reccs'>
            <Button>View All</Button>
          </Link>
        </HeaderWrap>

        <Albums {...latestAlbums} preview={true} />
      </MobileLatestAlbums>

      <Selectors {...latestSelectors} isHome={true} />

      <InnerWrapCenter>
        <HeaderWrap>
          <h3>Features</h3>
          <Link to='/features'>
            <Button>View All</Button>
          </Link>
        </HeaderWrap>
        <Features {...latestFeatures} preview={true} />

        <HeaderWrap>
          <h3>Genre Guides</h3>
          <Link to='/guides'>
            <Button>View All</Button>
          </Link>
        </HeaderWrap>
        <Guides {...latestGuides} preview={true} />
      </InnerWrapCenter>
    </HomeLayout>
  );
};

export const query = graphql`
  query {
    latestInterview: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { category: { name: { eq: "Interview" } } }
      limit: 1
    ) {
      edges {
        node {
          title
          subtitle
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          subcategory {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    latestAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      limit: 14
    ) {
      edges {
        node {
          englishTitle
          releaseDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
            slug
          }
          genre {
            name
          }
          artwork {
            url
          }
        }
      }
    }
    latestSelectors: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { eq: "Selector" } } }
      limit: 7
    ) {
      edges {
        node {
          artists {
            englishName
          }
          coverImage {
            url
          }
          slug
          selectorID
        }
      }
    }
    latestFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { ne: "Selector" } } }
      limit: 6
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    latestGuides: allStrapiGenreGuides(
      sort: { fields: publishedDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          coverImage {
            url
          }
          albumRecommendations {
            albumName
            artistName
            coverImage {
              url
            }
          }
        }
      }
    }
  }
`;

export default Home;
