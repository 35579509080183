import React from 'react';

import { Footer, Header, Navigation, MobileNav } from 'components';

import {
  Container,
  HeaderWrap,
  HeroText,
  TextWrap,
  HeroImage,
  Content,
} from './styled';

const HomeLayout = ({ children, navColor, edges }) => {
  const {
    slug,
    englishName,
    coverImage,
    artists,
    subcategory,
    category,
    subtitle,
    publishedDate,
  } = edges[0].node;

  return (
    <Container>
      <HeroText>
        <TextWrap>
          <p>
            <strong>{subcategory.name}</strong> <span>|</span>
            {publishedDate}
          </p>
          <h1>
            <a
              href={`/${
                subcategory.name == 'Selector' ? 'selector' : 'features'
              }/${edges[0].node.slug}`}
            >
              {artists[0].englishName}
            </a>
          </h1>
          <p>{subtitle}</p>
        </TextWrap>
      </HeroText>
      <HeaderWrap>
        <Header color='white' />
      </HeaderWrap>
      <HeroImage
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${coverImage.url})`,
        }}
      >
        {/* <img src={coverImage.url} alt='' /> */}
      </HeroImage>
      <Navigation colorScheme={navColor} />
      <Content>
        {children} <Footer />
      </Content>
      <MobileNav />
    </Container>
  );
};

export default HomeLayout;
