import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  background-color: black;
`;

export const HeroText = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: calc(100vw - 96px);
  z-index: 2;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const TextWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translate(100px, -80px);
  color: white;
  p:first-child {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    transform: translateY(10px);
  }
  p:last-child {
    font-size: 16px;
    margin: 10px 0 0 0;
    width: 40%;
    line-height: 1.5;
  }
  span {
    margin: 0 25px;
  }
  h1 {
    padding: 0;
    width: 75%;
    margin: 0;
    a {
      color: white;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const HeaderWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  width: calc(100vw - 120px);
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const HeroImage = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  img {
    width: 100%;
    object-fit: cover !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const Content = styled.div`
  position: relative;
  width: calc(100vw - 82px);
  background-color: #f1f1f1;
  margin-left: 82px;
  z-index: 3;
  transform: translateY(100vh);
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    transform: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
    transform: none;
  }
`;
